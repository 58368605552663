<template>
  <v-card>
    <v-card-title class="">
      <span class="me-3">({{ userList.length }}) Prospects</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-card-text>
      <b style="margin: 4px; padding: 4px; border: 1px solid grey; border-radius: 5px">
        <font-awesome-icon @click="copyAdMessageTitle()" icon="fa-solid fa-copy" class="ma-1"/>
        <b>Title:</b>
        <span contenteditable="true" id="advertising_message_title"> {{ advertising_message.title }}</span>
      </b>
      <p style="margin: 4px; padding: 4px; border: 1px solid grey; border-radius: 5px">
        <font-awesome-icon @click="copyAdMessageContent()" icon="fa-solid fa-copy" class="ma-1"/>
        <b>Message:</b> 
        <span contenteditable="true" id="advertising_message"> {{ advertising_message.content }}</span>
      </p>
      <p style="margin: 4px; padding: 4px; border: 1px solid grey; border-radius: 5px">
        <font-awesome-icon @click="copyAdMessageLink()" icon="fa-solid fa-copy" class="ma-1"/>
        <b>Link</b>
        <span contenteditable="true" id="advertising_message_link"> {{ advertising_message.link }}</span>
      </p>
      <p style="margin: 4px; padding: 4px; border: 1px solid grey; border-radius: 5px">
        <b>Download File:</b> <a :href="advertising_message.get_file" id="advertising_message_get_file">{{ advertising_message.get_file }}</a>
      </p>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="userList"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort wrap
      :search="search"
    >
      <!-- name -->
      <template #[`item.name`]="{item}">
        <div class="d-flex flex-column" style="cursor: pointer">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
        </div>
      </template>

      <template #[`item.id`]="{item}">
        <input type="checkbox" :id="'checkbox_'+item.id"/>
      </template>

      <template #[`item.phone_number`]="{item}">
        <a 
          v-if="$route.params.message_means === 'Whatsapp'" 
          @click="checkCheckBox(item.id)" 
          :href="'https://api.whatsapp.com/send?phone='+item.phone_number+'/&text=I salut you *'+item.name+'* 😃, i hope you are doing great 💫 today. %0a I\'m a PettyCash core team member, and am glad to bring you the following on our services.'" 
          target="_blank"
          >
            <v-btn class="primary" elevation="6" icon small>
              <font-awesome-icon icon="fa-solid fa-share-from-square"/>
            </v-btn>
        </a>
        <a v-else @click="checkCheckBox(item.id)">
          Still to Implement
        </a>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'

export default {
  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Withdrawal: 'primary',
      Deposit: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      switch1: true,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Message Sent', value: 'id' },
        { text: 'Send', value: 'phone_number' },
      ],
      userList: [],
      advertising_message: [],
      statusColor,
      search: '',

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      this.getProspectsAdMessage();
      document.title = "TopUp | List of Transactions"
  },

  methods:{
    checkCheckBox(item_id){
      const checkbox = document.getElementById("checkbox_"+item_id)
      checkbox.checked = true;
      this.$store.commit('setSnackBarMessage', this.$route.params.message_means+" message sent.")
      this.$store.commit('setSnackBarColor', "green darken-4")
      this.$store.commit('activateSnackBar', true)
    },
    
    copyAdMessageTitle(){
      const advertising_message_title = document.getElementById("advertising_message_title")
      navigator.clipboard.writeText(advertising_message_title.textContent)
      this.$store.commit('setSnackBarMessage', "Message title successfully copied.")
      this.$store.commit('setSnackBarColor', "green darken-4")
      this.$store.commit('activateSnackBar', true)
    },

    copyAdMessageContent(){
      const advertising_message = document.getElementById("advertising_message")
      navigator.clipboard.writeText(advertising_message.textContent)
      this.$store.commit('setSnackBarMessage', "Message content successfully copied.")
      this.$store.commit('setSnackBarColor', "green darken-4")
      this.$store.commit('activateSnackBar', true)
    },
    copyAdMessageLink(){
      const advertising_message_link = document.getElementById("advertising_message_link")
      navigator.clipboard.writeText(advertising_message_link.textContent)
      this.$store.commit('setSnackBarMessage', "Message link successfully copied.")
      this.$store.commit('setSnackBarColor', "green darken-4")
      this.$store.commit('activateSnackBar', true)
    },

    async getProspectsAdMessage(){
      const group_id = this.$route.params.group_id  
      const message_id = this.$route.params.message_id  
      const message_means = this.$route.params.message_means  
      const start_date = this.$route.params.start_date  
      const end_date = this.$route.params.end_date  

      let formData = new FormData()
      formData.append('group_id', group_id)
      formData.append('message_id', message_id)
      formData.append('message_means', message_means)
      formData.append('start_date', start_date)
      formData.append('end_date', end_date)

      await axios
        .post('/api/v1/topup/get/prospects/ad_message/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(response => {
          this.userList = response.data["prospects"]  
          this.advertising_message = response.data["advertising_message"]  
          console.log(this.userList)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    getMoreInfo(info_id){
      this.$store.commit('setDialogTitle', "Getting More Information for "+info_id)
      this.$store.commit('setDialogMessage', "Your information has been collected. <b>After</b> choosing this, the next field you will have to choose the number of days/weeks/months. <ul><li>This is one</li><li>This is two</li><li>This is three</li><li>This is four</li></ul>")
      this.$store.commit('activateDialog', true)
    }
  },
}
</script>
